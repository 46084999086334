/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  height: 100vh;
  justify-content: center;
}
.UberLogo2{
  padding-left: 12px;
}
.login-header {
  background-color: #000000;
  width: 100%;
  padding: 1rem 0rem;
  display: flex;
  justify-content: left;
  position: absolute;
  top: 0;
  left: 0;
}
.LetsEatlogo{
  width:90px;
  margin-left: 15%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem; /* Adjusted to push the form below the fixed header */
  width: 100%;
  max-width: 350px;
}

.form-label {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: left;

  width: 100%;
}

.form-input {
  width:94%;
  padding: 0.75rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 0px solid #ccc;
  background-color: rgb(238, 238, 238);
max-width: 350px;
  border-radius: 5px;
}

.form-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #000;
  color: #eeeeee;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  max-width: 350px;
}

.separator {
  font-size: 1rem;
  color: rgb(136, 136, 136);
  margin: 1rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #888;
}

.separator::before {
  margin-right: 1rem;
}

.separator::after {
  margin-left: 1rem;
}

.google-button,
.apple-button,
.qr-button {
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-button {
  background-color: #eeeeee;
  max-width: 350px;
}

.apple-button {
  background-color: #eeeeee;
  max-width: 350px;
}

.qr-button {
  background-color: #eeeeee;
  max-width: 350px;
}

.terms-text {
  font-size: 0.75rem;
  color: #666;
  text-align: center;
  margin-top: 1rem;
  max-width: 400px;
}
