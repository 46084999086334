.slick-prev {
  border-radius: 50% !important;
  position: absolute !important;
  left: 8px !important;
  z-index: 1 !important;
}

.slick-next {
  border-radius: 50% !important;
  position: absolute !important;
  right: 8px !important;
  z-index: 1 !important;
}


.slick-prev:hover::before,
.slick-next:hover::before {
  color: rgb(148, 148, 148) !important;
}

.slick-prev::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute;
  right: 2px !important;
  top: 2px !important;
}


.slick-next::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute !important;
  left: 6px !important;
  top: 2px !important;
}
.custom-arrow1 .slick-prev::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute !important;
  right: 2px !important;
  top: 2px !important;
}


.custom-arrow1  .slick-next::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute !important;
  left: 6px !important;
  top: 2px !important;
}

.parent-display .slick-arrow {
  display: none !important;
}