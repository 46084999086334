.dialog-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  padding-left: 15%;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}

.close-button {
  background-color: #f3f3f3 !important;
  position: absolute;
  left: 10px;
}

.title-text {
  margin-left: 25%;
  font-size: 20px;
  font-weight: 400;
  color: #000;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  height: calc(
    100% - 64px
  ); /* Adjusting for the height of the title and done button */
  justify-content: space-between;
}

.done-button-container {
  padding: 16px;
}
.promo-input {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.promo-textfield {
  .MuiInputBase-root {
    height: 40px; /* Set a fixed height for the TextField */
  }
}

.promoText {
  padding: 0px 100px;
}

.apply-button {
  height: 40px; /* Set the same height for the Button */
  background-color: #000 !important;
  color: #fff;
  box-shadow: none;
  font-size: 12px !important;
  font-weight: bold !important;

  margin-left: 12px !important;
  &:hover {
    background-color: #333;
  }
  margin-left: 5%;
}

.promo-banner {
  display: flex;
  align-items: center;
  background-color: #fff7e6;
  border-radius: 8px;
  padding: 16px;
}

.promo-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  margin-left: 50px;
}

.promo-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.promo-title {
  margin-bottom: 8px;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #000;
}
.join-now-button {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-weight: bold !important;
  text-transform: none;
  padding: 2px !important; /* Adjust padding to make it look like the provided image */
  border-radius: 16px !important;
  font-size: 10px !important; /* Adjust font size to match the provided image */
  box-shadow: none !important; /* Remove shadow */
  border: 1px solid #d1d1d1; /* Add a border to match the image */
  width: 25px;
  &:hover {
    background-color: #f0f0f0;
  }
  margin-top: 30px !important;
}

.done-button {
  height: 40px; /* Set the same height for the Button */
  background-color: #000 !important;
  color: #fff;
  box-shadow: none;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #333;
  }
}
