.slick-prev {
  border-radius: 50% !important;
  position: absolute !important;
  left: 60px !important;

  z-index: 1 !important;
}
.slick-next {
  border-radius: 50% !important;
  position: absolute !important;
  right: 8px !important;
  z-index: 1 !important;
} 
.cuisine .slick-prev::before {
  border-radius: 50% !important;
  position: absolute !important;
  right: 8px !important;
  z-index: 1 !important;
  left: -60px !important;
}


.custom-arrow .slick-prev {
  font-size: 35px !important;
  opacity: 1 !important ;
  color: rgb(203 203 203) !important;
  position: relative;
  top: -22% !important;
  left: 95% !important;
}
.custom-arrow .slick-next {
  font-size: 35px !important;
  opacity: 1 !important ;
  color: rgb(203 203 203) !important;
  position: relative;
  top: -22% !important;
  left: 96% !important;
}
.custom-arrow .slick-prev::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute !important;
  right: 10px !important;
  top: -2px !important;
}


.custom-arrow  .slick-next::before {
  font-size: 35px !important;
  opacity: 1 !important;
  color: rgb(203 203 203) !important;
  position: absolute !important;
  /* left: 6px !important; */
  top: -2px !important;
}

@media (max-width: 1168px) {
  .custom-arrow .slick-prev {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    /* left: 88% !important; */
  }

  .custom-arrow .slick-next {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    /* left: 89% !important; */
  }
}

@media (max-width: 868px) {
  .custom-arrow .slick-prev {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    /* left: 85% !important; */
  }

  .custom-arrow .slick-next {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    /* left: 86% !important; */
  }
}

@media (max-width: 668px) {
  .custom-arrow .slick-prev {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    left: 90% !important;
  }

  .custom-arrow .slick-next {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    left: 91% !important;
  }
  .cuisine .slick-next::before , .cuisine .slick-prev::before{
    top:13px !important
  }
}
