body {
  margin: 0;
  padding: 0;
  height:"100vh" !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
 font-family: sans-serif !important;
  padding: 15px !important;
  box-sizing: border-box;
}
#dropdown-font{
  background: none !important;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ccc;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: scroll;
}
.css-au2vfx-MuiStepper-root , .MuiStepper-root{
  width:"98%" !important;
  padding-left:"14px" !important;
}

.dropdown-list div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-list div:hover {
  background-color: #ddd;
}


 .subscribe {
  background-image: url("./app/assets/images/signup_rec.png");
  background-position: center;
  background-size: cover;
  padding: 5rem 2rem 5rem;
  text-align: center;
  border-radius: 2rem;
}

 .subscribe h3 {
  font-size: 2rem;
  color: #fff;
}

 .subscribe .subscribe-input {
  width: 70%;
  margin: 2rem auto 1.4rem;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

 .subscribe .subscribe-input select {
  width: 28%;
  border-radius: 20px 0 0 20px !important;
  padding-left: 1rem;
  height: 3.1rem;
  padding-right: 0;
  font-size: 68%;
  -webkit-appearance: none;
  background-color: white !important;
  color: #555;
}

 .subscribe .subscribe-input input {
  width:72%;
  height: 3rem;
  padding-left: 4rem;
  border-radius: 0 20px 20px 0;
  border: none;
  color: #555;

}
 .subscribe .subscribe-input input:active ,.subscribe .subscribe-input input:focus {
  outline: none;
  border: none;
}

 .subscribe .subscribe-input .btn {
  position: absolute;
  right: 1%;
  padding: 1.4rem 6rem;
  border-radius: 2rem;
}

 .subscribe .home-signup {
  color: #fff;
  cursor: pointer;
}

 .subscribe .home-signup:hover {
  color: #ef4223;
}
.subscribe .subscribe-input .btn {
  position: absolute;
  right: 3%;
  top:5px;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
}
.btn-danger{
  background-image: linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%);
  color: #fff;
  transition: .5s;
  background-size: 200% auto;
}

.text-white u{
  color:white  
}
.content h3{
  -webkit-text-stroke: 0.7px #fff;
  letter-spacing: 3.5px;

}
@media(max-width: 768px) {
   .subscribe {
      padding:3rem 0;
  }

   .subscribe .subscribe-input {
      width: 98%;
      transform: scale(0.9);
  }
  .subscribe .subscribe-input .form-control {
    padding-left: 2rem;
    height: 2.3rem;
  }
  .subscribe .subscribe-input select {
    padding: 1px !important;
  }

   .subscribe .subscribe-input .btn {
      padding: .2rem .5rem;
      font-size: 12px;
    top:7px;

  }
  .text-white u ,.home-signup{
  font-size: 13px;
  }
  
   
  .subscribe h3{
    font-size: 1rem;
  }
  .subscribe .subscribe-input select {
    height: 2.4rem !important;
  }


}
@media(max-width: 500px) {
  .subscribe .subscribe-input select {
    width: 40% !important;

  }
}