/* Container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
/* Container for the back button and heading */
.header-container {
  display: flex;
  align-items: center;
  /* padding: 16px; */
  margin-bottom: 24px;
  justify-content: space-around;
}
/* Back Button styles */
.back-button {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  margin-right: 16px;
}

.back-button-icon {
  font-size: 1.5rem;
  margin-right: 8px;
}

/* My Orders Heading styles */
.my-orders-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

/* Description text below the heading */
.description-text {
  color: #888;
  margin-bottom: 0px;
}

/* Icon styles */
.icon {
  font-size: 5rem !important;
  color: #bdbdbd !important;
  margin-bottom: 16px !important; 
}

/* Button styles */
.styled-button {
  margin-top: 20px !important; 
  padding: 8px 12px !important; 
  background-color: #000 !important;
  border-radius: 20px !important;
  color: #fff !important;
}

.styled-button:hover {
  background-color: #333;
}
/* Order Container styles */
.order-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center;  */
  width: 100%;
  box-sizing: border-box; 
  overflow-x: hidden; 
}

/* General Card Styling */
.order-card {
  display: flex;
  width: 100%;
  max-width: 500px; 
  margin-bottom: 32px;
}

@media (min-width: 1068px) {
 

  .order-card {
    flex: 1 1 calc(40% - 16px); 
    /* max-width: calc(40% - 16px); */
    margin-bottom: 32px;
    margin-right: 16px; 
  }

 
  .order-card:nth-child(2n) {
    margin-right: 0;
  }


}

/* Order Card Content styles */
.order-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Order Card Media styles */
.order-card-media {
  width: 200px;
  /* height: 150px; */
object-fit: fill;
  margin-right: 16px;
}

/* Order Reorder Button styles */
.order-button {
  margin-top: 8px;
  background-color: #6a6a6a !important;
  border-radius: 20px;
  color: #fff;
}

.order-button:hover {
  background-color: #0056b3;
}

.order-status {
  display: flex;
  align-items: center;
  margin-top: 4px; 
  color: #d32f2f;
  font-size: 14px;
}

.status-dot {
  width: 8px;
  height: 8px;
  background-color: #d32f2f; 
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.toggle-button {
  display: flex;
  background-color: #f0f0f0; 
  border-radius: 20px; 
  overflow: hidden;
  width: 221px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 16px;
  position: relative;
  border:  2px solid #dddddd; 
}

.toggle-option {
  flex: 1;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s;
  background-color: transparent; 
  border-radius: 20px; 
  color: black; 
}

.toggle-option:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.toggle-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: #ffffff; 
  border-radius: 20px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 300ms ease-in-out; 
}

.toggle-slider.slide-right {
  transform: translateX(100%);
}

.toggle-option.active {
  color: black;
}

@media (max-width: 1068px) {
  .toggle-button {
    display: flex;
    background-color: #c6c6c6;
    border-radius: 25px;
    overflow: hidden;
    width: 170px;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 10px;
    position: relative;
  }
  .toggle-option {
    flex: 1;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    transition: color 0.3s;
  }

  .my-orders-heading {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  

}
