.footerV2 {
  background: url("../../assets/images/footer.png");
  background-position: top;
  padding-top: 4rem;
  font-size: 1.8rem;
  margin-top: 4rem;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.FooterLogo {
  width: 100px;
}

.footer-column a {
  
  text-decoration: none;
  margin-bottom: 0.75rem;
  /* font-size: 1rem; */
}


.social-icons {
  display: flex;
  gap: 1rem;
}


.footer-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-top: 1px solid #eaeaea;
  padding-block: 1rem;
  width: 100%;
}

@media screen and (max-width:900px)  {
  .FooterLogo{
    margin: 0 auto !important;
  }
}