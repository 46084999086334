/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  z-index: 1000;
}

.UberLogo2 {
  width: 70px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.toggle-button {
  display: flex;
  background-color: #818181;
  padding: 5px 0;
  border-radius: 25px;
  overflow: hidden;
  width: 170px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 14px;
  position: relative;
}

.toggle-option {
  flex: 1;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  transition: color 0.3s;
}

.toggle-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.toggle-slider.slide-right {
  transform: translateX(100%);
}

.toggle-option.active {
  color: black;
}

.location,
.time {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-right: 0.5rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 0px solid #ccc;
  background-color: #f3f3f3;
  border-radius: 9999px;
  outline: none;
}

.navbar-right {
  display: flex;
  align-items: left;
  position: relative;
}

.navbar-center {
  display: flex;
  align-items: center;
}

.cart-badge {
  background-color: green;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.35rem;
  font-size: 0.5rem;
  position: absolute;
  top: -0.5rem;
  right: -0rem;
}

@media (max-width: 768px) {

  .toggle-button {
    width: 100px;
    font-size: 12px;
  }

  .UberLogo2 {
    width: 50px;
  }

  .location,
  .time {
    display: flex;
    align-items: left;
    font-size: 6px;
  }

  .search-input {
    padding: 0.3rem 0.5rem;
    font-size: 0.875rem;
  }

  .navbar-right {
    margin-top: 10px;
  }
}