/* Drawer.css */
.drawer {
  width: 280px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.drawer-header {
  display: flex;
  align-items: center;
  padding: 26px;
}

.user-icon {
  font-size: 48px;
  margin-right: 8px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
  font-size: 1rem;
}

.manage-account {
  color: #00c621;
  font-size: 0.875rem;
  cursor: pointer;
}

.drawer-menu {
  flex-grow: 1;
  color: black;
  font-weight: 500;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  text-decoration: none;
  color: inherit;
}

.menu-icon {
  margin-right: 0px;
  color: black;
}

.menu-text {
  color: black;
}

.UberOne {
  display: flex;
  flex-direction: column;
}

.menu-subtext {
  color: #e4682f;
  font-size: 0.75rem;
}

.drawer-footer {
  padding: 16px;
  background-color: #f5f5f5;
}

.footer-item {
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
}

.app-promo {
  display: flex;
  align-items: center;
}

.app-logo {
  width: 36px;
  margin-right: 8px;
}

.promo-text {
  font-size: 0.875rem;
  color: #555;
}

.app-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  text-decoration: none;
  color: black;
}

.app-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.app-icon {
  margin-right: 4px;
}
