.custom-arrow1 .slick-prev {
    border-radius: 50% !important;
    position: absolute !important;
    left: 60px !important;
    z-index: 1 !important;
  }
  .custom-arrow1  .slick-next {
    border-radius: 50% !important;
    position: absolute !important;
    right: 8px !important;
    z-index: 1 !important;
  }
  .custom-arrow1 .slick-prev {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    left: 95% !important;
  }
  .custom-arrow1 .slick-next {
    font-size: 35px !important;
    opacity: 1 !important ;
    color: rgb(203 203 203) !important;
    position: relative;
    top: -22% !important;
    left: 96% !important;
  }
  .custom-arrow1 .slick-prev::before {
    font-size: 35px !important;
    opacity: 1 !important;
    color: rgb(203 203 203) !important;
    position: absolute !important;
    right: 10px !important;
    top: 5px !important;
  }
  
  
  .custom-arrow1  .slick-next::before {
    font-size: 35px !important;
    opacity: 1 !important;
    color: rgb(203 203 203) !important;
    position: absolute !important;
    /* left: 6px !important; */
    top: 5px !important;
  }
  
  @media (max-width: 1168px) {
    .custom-arrow1 .slick-prev {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -22% !important;
      /* left: 88% !important; */
    }
  
    .custom-arrow1 .slick-next {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -22% !important;
      /* left: 89% !important; */
    }
  }
  
  @media (max-width: 868px) {
    .custom-arrow1 .slick-prev {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -22% !important;
      /* left: 85% !important; */
    }
  
    .custom-arrow1 .slick-next {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -22% !important;
      /* left: 86% !important; */
    }
  }
  
  @media (max-width: 668px) {
    .custom-arrow1 .slick-prev {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -18% !important;
      left: 90% !important;
    }
  
    .custom-arrow1 .slick-next {
      font-size: 35px !important;
      opacity: 1 !important ;
      color: rgb(203 203 203) !important;
      position: relative;
      top: -18% !important;
      left: 91% !important;
    }
  }
 
  
  