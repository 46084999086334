/* CheckoutPage.css */

.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 01rem;
  padding-left: 8%;
  padding-right: 5%;
  background-color: #f3f3f3;
}

.checkout-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  background-color: white;
}

.checkoutLogo {
  width: 5rem;
  padding-top: 1rem;
}

.back-link {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  text-decoration: none;
  color: black;
  font-weight: 500  ;
  margin-top: 15px;
}

.icon {
  margin-right: 8px;
}

.checkout-title {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.checkout-content {
  display: flex;
  width: 100%;
  /* max-width: 1200px; */
  gap: 2rem;
}

.checkout-details,
.order-summary {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout-details {
  flex: 3.5;
  padding-right: 16px;
  width: 100%;
}
.checkout-details div {
  margin-bottom: 15px;
}
.order-summary {
  flex: 1;
}
.delivery-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.toggle-button2 {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 25px;
  overflow: hidden;
  width: 200px; /* Adjust the width as needed */
  max-height: 30px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0px !important;
  font-size: 14px;
  position: relative;
  left: 0%;
}

.toggle-option {
  flex: 1;
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  z-index: 1;
  margin-bottom: 0px !important;
  transition: color 0.3s;
}

.toggle-slider {
  position: absolute;
  margin-bottom: 0px !important;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.toggle-slider.slide-right {
  transform: translateX(100%);
}

.toggle-option.active {
  color: black;
}

.order-summary {
  flex: 2;
}

.card {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}


.payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.invoice-requestcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.invoice-request {
  display: flex;
  flex-direction: column;
}
.delivery-location {
  display: flex;
}

.delivery-city {
  margin-bottom: 0px;
}

.delivery-country {
  position: relative;
  margin-top: 0px;
  font-size: 12px;
  color: #525252;
}

.delivery-instructionscontainer,
.delivery-locationcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delivery-instructions {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  margin-bottom: 1rem;
}

.delivery-instructions p {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.add-instructions,
.add-tax-details {
  font-size: 0.8rem;
  color: green;
  text-decoration: none;
  margin-top: -5px;
}

.option {
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.option.selected {
  border: 2px solid black;
}

.continue-button {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
}

.edit-button {
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 0.7rem;
  margin-top: 2%;
  border: none;
  cursor: pointer;
  border-radius: 1.5rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: black;
  transition: 0.2s ease-in-out;
  max-height: 30px;
}

.edit-button:hover {
  background-color: #dddddd;
}
.order-summary {
  width: 100%; /* Adjust width to fit your layout */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: none; /* This ensures the sidebar doesn't grow or shrink */
}

.order-restaurant {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.restaurant-img {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  object-fit: cover;
  margin-right: 12px;
}

.restaurant-info h3 {
  margin: 0;
  font-size: 1rem;
  color: #333;
  font-weight: 600;
}

.restaurant-info p {
  margin: 4px;
  font-size: 0.875rem;
  color: #666;
}

.order-promotion {
  background-color: #fff2d9;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.promotion-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-promotion p {
  margin: 0;
  font-size: 0.875rem;
  color: #333;
  padding-right: 50px; /* Ensure there is space between the text and the button */
}

.try-button {
  background-color: #3d2f0500;
  border: none;
  border-radius: 4px;
  padding: 6px 0px;
  cursor: pointer;
  font-size: 0.875rem;
  color: #333;
  margin-top: 8px; /* Adds space between the paragraph and the button */
}

.promotion-image {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the area without distorting */
}

.continue-button {
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 16px;
}
.cart-summary {
  padding: 10px 0px;
  margin: 0px;
  background-color: #fff;
  border-radius: 4px;
}

.cart-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cart-items {
  margin-top: 10px;
}

.food-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.food-image-container {
  margin-right: 10px;
}

.food-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.food-details {
  flex-grow: 1;
}

.food-name {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.food-option {
  margin: 0;
  font-size: 12px;
  color: #666;
}

.food-price {
  font-size: 14px;
  color: #333;
}
.promotion {
  padding: 10px 0px;
  margin: 0px;
  background-color: #fff;
  border-radius: 4px;
}

.promotion-title {
  font-weight: bolder;
  margin-bottom: 10px;
}

.promotion-body {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.promotion-body p {
  margin: 0;
  font-size: 14px;
}
.order-total {
  padding: 10px 0px;
  margin: 0px;
  background-color: #fff;
  border-radius: 4px;
}

.order-total-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.total-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total-label {
  font-size: 14px;
}

.total-value {
  font-size: 14px;
}

.total-label-bold {
  font-weight: bold;
  font-size: 16px;
}

.total-value-bold {
  font-weight: bold;
  font-size: 16px;
}

.order-allergies {
  font-size: 0.75rem;
  color: #666;
  padding: 00px 0px;
  margin: 0px;
  background-color: #fff;
  border-radius: 4px;
}
